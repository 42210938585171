<template>
    <div class="flex flex-col overflow-auto" ref="el">
        <div
            class="group cursor-pointer border-b p-6"
            :class="
                [mailConversationId, roomId].includes(conversation.id)
                    ? 'bg-primary-50'
                    : 'hover:bg-gray-50'
            "
            v-for="conversation in data"
            :key="conversation.id"
            @click="openConversation(conversation)"
        >
            <div class="mb-2.5 flex items-start justify-between">
                <div>
                    <div class="w-44 truncate pr-3 text-sm font-medium">
                        {{ conversation.subject }}
                    </div>
                    <div class="text-xs">
                        {{
                            isEmailConversation(conversation)
                                ? conversation?.chat_room_email?.client_email
                                : getReceiverFullName(
                                      conversation.participants,
                                      centralUser
                                  )
                        }}
                    </div>
                </div>

                <base-badge
                    v-if="conversation.latest_message"
                    class="group-hover:bg-primary-100 group-hover:text-primary-700"
                    variant="gray"
                    :label="
                        $t('timeAgo', {
                            diffDate: conversation.latest_message.diff_date,
                        })
                    "
                />

                <base-badge
                    v-else-if="conversation?.chat_room_email?.diff_date"
                    class="group-hover:bg-primary-100 group-hover:text-primary-700"
                    variant="gray"
                    :label="
                        $t('timeAgo', {
                            diffDate: conversation?.chat_room_email?.diff_date,
                        })
                    "
                />
            </div>

            <p
                v-if="conversation.latest_message"
                class="truncate text-sm font-medium text-gray-500"
            >
                {{ conversation.latest_message.message }}
            </p>

            <p
                v-else-if="conversation?.chat_room_email?.last_message"
                class="line-clamp-1 truncate text-sm font-medium text-gray-500"
                v-html="
                    $filters.sanitize(
                        conversation?.chat_room_email?.last_message
                    )
                "
            ></p>
        </div>
        <div
            v-if="isFetching"
            class="flex w-full items-center justify-center py-2"
        >
            <base-loading />
        </div>
    </div>
</template>

<script setup>
import { useInfiniteScroll } from '@vueuse/core'
import { getReceiverFullName } from '@tenant/utils/helper'
import { ECHO_EVENTS } from '@tenant/utils/constants'
import { isEmpty } from 'lodash-es'
import { useClientEmails } from '@tenant/composables/use-chat'

const props = defineProps({
    client: {
        type: Object,
        default: null,
    },
})

const { centralUser } = useAuth()

const el = ref(null)
const router = useRouter()
const route = useRoute()

const { data, onLoadMore, isFetching } = props.client
    ? useClientEmails(props.client.tenant_id)
    : useConversations()
const { newConversation, updateConversation } = useConversation()

const roomId = computed(() => route.query.conversation)
const mailConversationId = computed(() => route.query['mail-conversation'])

useListenEmitEcho(ECHO_EVENTS.CHAT_NEW, ({ chatRoom }) => {
    newConversation(chatRoom)
})
useListenEmitEcho(ECHO_EVENTS.CHAT_MESSAGE, ({ chatRoom }) => {
    updateConversation(chatRoom)
})
useListenEmitEcho(ECHO_EVENTS.EMAIL_MESSAGE, ({ chatRoom, message }) => {
    updateConversation(chatRoom, message)
})

useInfiniteScroll(
    el,
    () => {
        onLoadMore()
    },
    { distance: 10 }
)

const openConversation = (conversation) => {
    router.replace({
        query: isEmailConversation(conversation)
            ? { 'mail-conversation': conversation.id }
            : { conversation: conversation.id },
    })
}

const isEmailConversation = (conversation = {}) => {
    return !isEmpty(conversation.chat_room_email)
}
</script>
